import { ResponsiveVideoFragment } from 'gatsby/graphqlTypes';
import type { PlyrEvent } from 'plyr';
import type Plyr from 'plyr';
import React from 'react';
import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

export enum AppErrorName {
  LogOutError = 'error_logout',
  PlyrError = 'plyr_error',
  VideoError = 'video_error',
  RemoteCartMutation = 'remote_cart_mutation',
  StripePaymentFailedUnexpectedly = 'stripe_payment_error',
  ImageError = 'image_error'
}

export type AppErrors = {
  [AppErrorName.LogOutError]: {
    error: unknown;
  };
  [AppErrorName.PlyrError]: {
    event: PlyrEvent;
    sourceInfo: Plyr.SourceInfo;
    mediaError?: MediaError | null;
  };
  [AppErrorName.VideoError]: {
    video: ResponsiveVideoFragment;
    mediaError?: MediaError | null;
  };
  [AppErrorName.RemoteCartMutation]: {
    error: unknown;
    message: string;
  };
  [AppErrorName.StripePaymentFailedUnexpectedly]: {
    paymentIntentId?: string;
  };
  [AppErrorName.ImageError]: {
    event: React.SyntheticEvent<HTMLImageElement | HTMLSourceElement>;
    image: GqlSchemaWorker.ProductThumbnailFragment;
  };
};

export type AppErrorPayload<T extends AppErrorName> = AppErrors[T];

export function isAppErrorName(x: unknown): x is AppErrorName {
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    typeof x === 'string' && Object.values(AppErrorName).includes(x as any)
  );
}
