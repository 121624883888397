import { LogOpts, ScopedLogger, isLogOpts } from './';
import { isEmptyRecord, isRecord } from '../util';

import { MethodFactory } from 'loglevel';
import { SeverityLevel, User } from '@sentry/browser';
import { loadSentryCli } from '../sentry/loader';
import {
  AppErrorName,
  AppErrors,
  isAppErrorName
} from '../../lib/events/errors';
import { get } from 'svelte/store';
import { connectionStore } from '../../lib/device/state';

// const isSentryEnabled = (
//   Sentry: typeof import('../../../node_modules/@sentry/browser/types/index')
// ) => !Sentry.getCurrentHub().getClient()?.getOptions().enabled;

const IS_CONSOLE_ENABLED = process.env.GATSBY_CONSOLE_ENABLED === 'true';

export function getAppErrorName(error: Error): AppErrorName | undefined {
  if (isAppErrorName(error.message)) {
    return error.message;
  }
}

function parseErrorArgs(
  error: Error,
  data: Record<string, unknown> = {}
): [Error | string, Record<string, unknown>] {
  const appErrorName = getAppErrorName(error);
  if (appErrorName) {
    if (appErrorName === AppErrorName.PlyrError) {
      const d = data as AppErrors[AppErrorName.PlyrError];
      const plyrData = {
        ...d,
        mediaError: d.mediaError && {
          code: d.mediaError.code,
          message: d.mediaError.message
        }
      };
      return [error, plyrData];
    }

    if (appErrorName === AppErrorName.VideoError) {
      const d = data as AppErrors[AppErrorName.VideoError];
      const errorData = {
        ...d,
        mediaError: d.mediaError && {
          code: d.mediaError.code,
          message: d.mediaError.message
        }
      };
      return [error, errorData];
    }
    return [error, data];
  }

  if (error instanceof Error) {
    return [error, data];
  }

  return [
    new Error('Unknown application error'),
    {
      message: 'Unable to determine type of error',
      data
    }
  ];
}

export default function withSentry(logger: ScopedLogger): ScopedLogger {
  const rawMethod = logger.methodFactory;
  let user: User | undefined;

  // TODO: gatsby doesn't allow this
  // TODO: logger should unsubscribe if destroyed
  // disruptorServiceWorkerRemote?.query(
  //   {
  //     key: 'session',
  //     variables: {}
  //   },
  //   ({ data }) => {
  //     const session = (data as SessionQuery)?.currentUser;
  //     user = session
  //       ? {
  //           uid: session.uid,
  //           email: session.profile?.email || session.email || undefined,
  //           name: session.profile
  //             ? `${session.profile.firstName} ${session.profile.surname1}`
  //             : undefined
  //         }
  //       : undefined;
  //   }
  // );

  const factory: MethodFactory = (methodName, logLevel, loggerName) => {
    const name = loggerName ? loggerName.toString() : '';

    const defaultMethod = rawMethod(methodName, logLevel, loggerName);

    switch (methodName) {
      case 'error':
        return ((_error, _data) => {
          const [error, data] = parseErrorArgs(_error, _data);

          loadSentryCli().then(SentryCli => {
            if (data) {
              SentryCli.setContext('Logger error data', data);
            }

            const connectionState = get(connectionStore);
            if (connectionState) {
              SentryCli.setContext('Connection state', connectionState);
            }

            SentryCli.captureException(
              error,
              SentryCli.getEventContext(methodName, user)
            );

            IS_CONSOLE_ENABLED && defaultMethod(error, data);
          });
        }) as ScopedLogger['error'];

      default:
        return (...args: unknown[]) => {
          const defaultOpts: LogOpts = { capture: false };
          const [messages, opts, data] = args.reduce<
            [unknown[], LogOpts, Record<string, unknown>]
          >(
            ([messages, opts, data], x) => {
              if (isLogOpts(x)) {
                opts = x;
              } else if (isRecord(x)) {
                data = { ...data, ...x };
              } else {
                messages.push(x);
              }

              return [messages, opts, data];
            },
            [[], defaultOpts, {}]
          );

          const message = messages.join(' ');

          loadSentryCli().then(SentryCli => {
            let level: SeverityLevel;
            switch (methodName) {
              case 'warn':
                level = 'warning';
                break;
              case 'trace':
                level = 'debug';
                break;
              default:
                level = methodName;
            }
            if (opts.capture) {
              if (opts.event) {
                SentryCli.captureEvent(opts.event);
              } else {
                SentryCli.captureMessage(
                  message,
                  SentryCli.getEventContext(level, user)
                );
              }
            } else {
              SentryCli.addBreadcrumb({
                message,
                data,
                category: name,
                level,
                timestamp: Date.now()
              });
            }

            const downstreamArgs = messages;
            !isEmptyRecord(data) && downstreamArgs.push(data);
            IS_CONSOLE_ENABLED && defaultMethod(...downstreamArgs);
          });
        };
    }
  };

  logger.methodFactory = factory;
  return logger;
}
