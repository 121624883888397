import { Logger, MethodFactory } from 'loglevel';

enum LogLevel {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  SILENT = 'silent'
}

const baseStyles = [
  'color: #fff',
  'background-color: #444',
  'padding: 2px 4px',
  'border-radius: 2px'
];

const logLevelErrors: Record<LogLevel, string[]> = {
  [LogLevel.TRACE]: [],
  [LogLevel.DEBUG]: ['background-color: #8E44AD'],
  [LogLevel.INFO]: ['background-color: #2874A6'],
  [LogLevel.WARN]: ['background-color: #D4AC0D'],
  [LogLevel.ERROR]: ['background-color: #A93226'],
  [LogLevel.SILENT]: []
};

function isLogLevelName(x: unknown): x is LogLevel {
  return Object.values(LogLevel).some(y => y === x);
}

export function withColor(logger: Logger): Logger {
  const rawMethod = logger.methodFactory;

  /**
   *
   * @param methodName
   * @param logLevel this will vary depending on log level set for the logger
   * @param loggerName
   * @returns
   */
  const factory: MethodFactory = (methodName, logLevel, loggerName) => {
    const style = isLogLevelName(methodName)
      ? baseStyles.concat(logLevelErrors[methodName]).join(';') + ';'
      : '';
    const name = loggerName ? loggerName.toString() : '';

    return (...args) => {
      return rawMethod(methodName, logLevel, loggerName)(
        `%c${methodName.toUpperCase()}${name ? ` ${name}` : ''}`,
        style,
        ...args
      );
    };
  };

  logger.methodFactory = factory;
  return logger;
}
