import * as GqlSchemaWorker from './gql';

import type { CombinedError } from '@urql/core';
import { loggers } from 'lib/log';

const log = loggers.service;

export class DisruptorExtensions {
  private retryDelayTimer: NodeJS.Timeout | number | null = null;
  private retryDelay: number;
  private retryDelayCallback: (n: number) => void = () => {};
  private errors: CombinedError['graphQLErrors'];

  constructor(readonly error: CombinedError) {
    this.errors = error.graphQLErrors || [];
    this.retryDelay = this.resourceExhausted()?.retry_delay || 0;
    if (this.retryDelay) {
      this.retryDelayTimer = setInterval(() => {
        if (this.retryDelay > 0) {
          this.retryDelay -= 1;
          this.retryDelayCallback(this.retryDelay);
        } else {
          this.clearRetryInterval();
          this.retryDelayCallback = () => {};
        }
      }, 1000);
    }
  }

  private clearRetryInterval() {
    if (this.retryDelayTimer) {
      clearInterval(this.retryDelayTimer);
    }
  }

  private get extensions() {
    return this.errors.reduce((acc, error) => {
      if (error.extensions) {
        acc.push(error.extensions as GqlSchemaWorker.DisruptorExtension);
      } else {
        log.warn('GraphQL error did not include any extensions');
      }
      return acc;
    }, [] as GqlSchemaWorker.DisruptorExtension[]);
  }

  findByCode(code: GqlSchemaWorker.GraphqlErrorCode) {
    return this.extensions.filter(ext => ext.code === code);
  }

  resourceExhausted() {
    return this.findByCode('RESOURCE_EXHAUSTED')[0] as
      | undefined
      | GqlSchemaWorker.ResourceExhaustedExtension;
  }

  setRetryCallback(cb: (n: number) => void) {
    this.retryDelayCallback = cb;
  }

  get retryDelayRemaining() {
    return this.retryDelay;
  }

  badUserInput() {
    return this.findByCode('BAD_USER_INPUT')[0] as
      | undefined
      | GqlSchemaWorker.BadUserInputExtension;
  }

  forbidden() {
    return this.findByCode('FORBIDDEN')[0] as
      | undefined
      | GqlSchemaWorker.ForbiddenExtension;
  }

  notFound() {
    return this.findByCode('NOT_FOUND')[0] as
      | undefined
      | GqlSchemaWorker.NotFoundExtension;
  }

  toJSON() {
    return this.errors.map(error => {
      return {
        message: error.message,
        locations: error.locations,
        path: error.path,
        extensions: this.extensions
      };
    });
  }
}
